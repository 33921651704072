import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image'
import { graphql } from 'gatsby';

import withTheme from '../theme';
import { Layout } from '../components1/layout';
import { Bold, Text, Title } from '../components1/Texts';
import LinedTitle from '../components1/LinedTitle';
import Subtitle from '../components1/Subtitle';
import Subtitle2 from '../components1/Subtitle2';
import SectionBeforeCustom from '../components1/Section';
import FlexDiv from '../components1/FlexDiv';
import { CabadiciButton } from '../components1/buttons';
import ParentheseBackgroundCarre from '../components1/ParentheseBackgroundCarre';

const GridContainer = styled.div`
    display: grid;
    max-width: 600px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(5, auto);
    grid-template-areas: 'a b b'
                        'c c d'
                        'e f f'
                        'g g h'
                        'i j j';
    grid-column-gap: 30px;
    grid-row-gap: 5px;
    margin-top: 10px;
    margin-bottom: 10px;

    @media only screen and (max-width: 599px) {
        grid-column-gap: 0px;
        grid-row-gap: 20px;
    }
`;

const originesData = [
    {
        title: 'Qui sommes nous ?',
        text: 'Deux amies très actives, jeunes mamans épicuriennes et attachées à leur région'
    },
    {
        title: 'D\'où venons nous ?',
        text: 'Vailhauquès, Teyran, Mauguio, Les Arceaux... Montpellier en long, en large et en travers !'
    },
];

const iciOuData = [
    {
        boldText: ['...alors nous avons acheté des livres', 'de cuisine, mais...'],
        text: 'il nous manquait toujours un ingrédient...',
        area: 'b',
        justify: 'right',
    },
    {
        boldText: ['...alors nous nous y sommes prises à l\'avance en', 'faisant des listes de courses, mais...'],
        text: 'ça nous prenait un peu trop de temps...',
        area: 'c',
        justify: 'left',
    },
    {
        boldText: ['...alors nous avons découvert les drives', 'des grandes surfaces, mais...'],
        text: 'certains produits ne nous convenaient pas toujours...',
        area: 'f',
        justify: 'right',
    },
    {
        boldText: ['...alors nous sommes allées dans des', 'enseignes de produits bio, mais...'],
        text: 'qui venaient parfois de très loin...',
        area: 'g',
        justify: 'left',
    },
    {
        boldText: ['...alors nous sommes allées au marché', 'le dimanche, mais...'],
        text: 'on ne savait plus quoi cuisiner...',
        area: 'j',
        justify: 'right',
    },
];

const TitleResponsive = styled(Title)`
    @media only screen and (max-width: 599px) {
        padding-top: 10px;
        font-size: 1.4rem;
        /* margin-left: 40px; */
        padding-bottom: 10px;
        white-space: nowrap;
    }
`;

const Section = styled(SectionBeforeCustom)`
    padding-left: 150px;
    padding-right: 150px;
    @media only screen and (max-width: 699px) {
        paddingLeft: 30px !important;
        paddingRight: 30px !important;
    }
`;

const IndexPage = withTheme(({ theme, data }) => (
    <Layout>
        {
            ({ openConnectForm }) => (
                <>
                    <Section
                        style={{
                            paddingTop: 40,
                            paddingBottom: 30
                        }}
                        css={`
                            @media only screen and (max-width:599px) {
                                padding-top: 15 !important;
                            }
                        `}
                    >
                        <TitleResponsive
                            style={{
                                textAlign: 'center'
                            }}
                        >{'D\'ici mais d\'où ?'}</TitleResponsive>
                        <Subtitle
                            color="jauneFaible"
                            alignement="flex-start"
                            css="padding: 0 40px;"
                        >
                            nos ingrédients
                        </Subtitle>
                        <div
                            style={{
                                paddingLeft: 50,
                                paddingRight: 50,
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 20,
                                    fontFamily: theme.font.title,
                                    textAlign: 'center',
                                    padding: '20px 0',
                                }}
                            >
                                {'Notre promesse n\'est pas de vous faire consommer du'}
                                <br />
                                {'100% Sud de France ou du 100% bio.'}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 20,
                                    fontFamily: theme.font.title,
                                    textAlign: 'center',
                                    paddingBottom: 20,
                                }}
                            >
                                Nous vous promettons par contre de tout faire pour :
                            </Text>
                        </div>
                        <FlexDiv
                            style={{
                                justifyContent: 'center',
                            }}
                        >
                            <Text style={{
                                fontSize: '0.75rem',
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                fontWeight: 600,
                                padding: 30,
                                maxWidth: 250,
                            }}>
                                toujours trouver des ingrédients de grande qualité
                            </Text>
                            <Text style={{
                                fontSize: '0.75rem',
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                fontWeight: 600,
                                padding: 30,
                                maxWidth: 250,
                            }}>
                                {'s\'approvisionner en direct chez des producteurs au plus près de chez nous'}
                            </Text>
                            <Text style={{
                                fontSize: '0.75rem',
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                fontWeight: 600,
                                padding: 30,
                                maxWidth: 250,
                            }}>
                                vous faire découvrir des perles rares de notre région
                            </Text>
                        </FlexDiv>
                    </Section>
                    <Image fluid={data.aliments1.childImageSharp.fluid} />
                    <Section
                        style={{
                            paddingTop: 10,
                            paddingBottom: 30
                        }}
                    >
                        <Text
                            style={{
                                fontSize: '1.25rem',
                                textAlign: 'center',
                                padding: 50,
                            }}
                            css={`
                                padding: 10px !important;
                                @media only screen and (max-width: 599px) {
                                    font-size: 1rem !important;
                                }
                            `}
                        >
                            #hérault #circuitscourts #montpellier #occitanie #aveyron #saison
                            <br />
                            #mauguio #directproducteurs #gard #grauduroi
                            <br />
                            #consommerlocal #lozère #languedocroussillon #aubrac #aop
                        </Text>
                    </Section>
                    <div
                        style={{
                            backgroundColor: theme.color.beigeFaible
                        }}
                    >
                        <Section>
                            <Subtitle
                                color="vertFort"
                                alignement="flex-start"
                                css="padding: 0 40px;"
                            >
                                nos producteurs
                            </Subtitle>
                            <div
                                style={{
                                    paddingBottom: 30
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >
                                    <Subtitle2 width="600px" top={4} css={`
                                        padding-top: 30px;
                                        @media only screen and (max-width: 599px) {
                                            padding-bottom: 20px;
                                        }
                                    `}>
                                        <Bold>Si certains ingrédients se trouvent en abondance autour de nous</Bold>
                                    </Subtitle2>
                                    fruits & légumes,
                                    <br/>
                                    fromage de chèvre & brebis,
                                    <br/>
                                    pâtes & riz,
                                    <br/>
                                    poissons de Méditerrannée...
                                </Text>
                                <Text
                                    style={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <Subtitle2
                                        alignement="center"
                                        position="center"
                                        color="jauneFaible"
                                        width="300px"
                                        top={4}
                                        css="padding-top: 30px;"
                                    >
                                        <Bold>{'... d\'autres viendront d\'un peu plus loin'}</Bold>
                                    </Subtitle2>
                                    {'viandes d\'Aubrac,'}
                                    <br/>
                                    crémeries de Lozère, ...
                                </Text>
                                <Text
                                    style={{
                                        textAlign: 'right',
                                        paddingBottom: 30,
                                    }}
                                >
                                    <Subtitle2
                                        alignement="flex-end"
                                        position="right"
                                        color="rougeFaible"
                                        width="200px"
                                        top={4}
                                        css="padding-top: 30px;"
                                    >
                                        <Bold>... voire de très loin</Bold>
                                    </Subtitle2>
                                    sauce soja,
                                    <br/>
                                    mozzarella, ...
                                </Text>
                                <Text
                                    style={{
                                        fontSize: '1.125rem',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                    }}
                                >
                                    ...pour pouvoir vous proposer des recettes aux origines variées !
                                </Text>
                            </div>
                        </Section>
                        <Image fluid={data.champ.childImageSharp.fluid} />
                    </div>
                    <Section
                        style={{
                            marginTop: 40
                        }}
                    >
                        <Subtitle
                            color="rougeFaible"
                            alignement="flex-start"
                            css="padding: 0 40px;"
                        >
                            nos origines
                        </Subtitle>
                        <FlexDiv
                            style={{
                                padding: 30
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    padding: 5px !important;
                                }
                            `}
                        >
                            {
                                originesData.map(({title, text}, index) => {
                                    return (
                                        <FlexDiv
                                            key={index.toString()}
                                            style={{
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                            css={`
                                                @media only screen and (max-width: 599px) {
                                                    padding: 0 5px !important;
                                                }
                                            `}
                                        >
                                            <Title
                                                style={{
                                                    fontWeight: 600,
                                                    fontSize: '1.5rem',
                                                    textAlign: 'center',
                                                    marginBottom: 20,
                                                }}
                                                css={`
                                                    @media only screen and (max-width: 599px) {
                                                        font-size: 0.875rem !important;
                                                    }
                                                `}
                                            >
                                                {title}
                                            </Title>
                                            <ParentheseBackgroundCarre>
                                                <Text
                                                    style={{
                                                        fontSize: '1rem',
                                                        fontWeight: 400,
                                                        maxWidth: 150,
                                                        textAlign: 'center',
                                                        alignSelf: 'center',
                                                        padding: 30,
                                                    }}
                                                    css={`
                                                        @media only screen and (max-width: 599px) {
                                                            font-size: 0.75rem !important;
                                                            padding: 0 10px !important;
                                                        }
                                                    `}
                                                >
                                                    {text}
                                                </Text>
                                            </ParentheseBackgroundCarre>
                                        </FlexDiv>
                                    )
                                })
                            }
                        </FlexDiv>
                    </Section>
                    <Section>
                        <LinedTitle
                            borderColor={theme.color.jauneFort}
                            borderWidth="1.5rem"
                            style={{
                                fontWeight: 300,
                                fontSize: "1.25rem",
                                textAlign: 'center',
                                padding: '30px 0',
                            }}
                        >
                            <Text
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 1.05rem !important;
                                        font-weight: 300 !important;
                                        padding: 0 10px !important;
                                    }
                                `}
                            >
                                {'Et l\'idée elle vient d\'où ?'}
                            </Text>
                        </LinedTitle>
                    </Section>
                    <Text
                        style={{
                            fontSize: 18,
                            textAlign: 'center',
                            padding: 30
                        }}
                        css={`
                            @media only screen and (max-width: 599px) {
                                font-size: 1rem !important;
                                padding-top: 0 !important;
                            }
                        `}
                    >
                        {'Parfois l\'inspiration tardait à arriver dans la cuisine ...'}
                    </Text>
                    <Section
                        style={{
                            marginBottom: 40,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <GridContainer
                            style={{padding: '0 30px'}}
                        >
                            {
                                iciOuData.map((d, index) => {
                                    return (
                                        <Text
                                            key={index.toString()}
                                            style={{
                                                textAlign: d.justify,
                                                alignSelf: 'center',
                                                gridArea: d.area
                                            }}
                                            css={`
                                                @media only screen and (max-width: 599px) {
                                                    font-size: 0.85rem !important;
                                                }
                                            `}
                                        >
                                            <Bold
                                                css={`
                                                    @media only screen and (max-width: 599px) {
                                                        font-size: 0.85rem !important;
                                                    }
                                                `}
                                            >{d.boldText[0]}</Bold>
                                            <br />
                                            <Bold
                                                css={`
                                                    @media only screen and (max-width: 599px) {
                                                        font-size: 0.85rem !important;
                                                    }
                                                `}
                                            >{d.boldText[1]}</Bold>
                                            <br/>
                                            {d.text}
                                        </Text>
                                    )
                                })
                            }
                            <Image
                                style={{
                                    gridArea: 'a',
                                    justifySelf: 'center',
                                    width: '100%',
                                    maxHeight: 150,
                                }}
                                fluid={data.livre.childImageSharp.fluid}
                                alt="livre"
                                imgStyle={{
                                    objectFit: 'contain',
                                }}
                            />
                            <Image
                                style={{
                                    gridArea: 'd',
                                    justifySelf: 'center',
                                    width: '100%',
                                    maxHeight: 150,
                                }}
                                fluid={data.chariot.childImageSharp.fluid}
                                alt="chariot"
                                imgStyle={{
                                    objectFit: 'contain',
                                }}
                            />
                            <Image
                                style={{
                                    gridArea: 'e',
                                    justifySelf: 'center',
                                    width: '100%',
                                    maxHeight: 150,
                                }}
                                fluid={data.drive.childImageSharp.fluid}
                                alt="drive"
                                imgStyle={{
                                    objectFit: 'contain',
                                }}
                            />
                            <Image
                                style={{
                                    gridArea: 'h',
                                    justifySelf: 'center',
                                    width: '100%',
                                    maxHeight: 150,
                                }}
                                fluid={data.local.childImageSharp.fluid}
                                alt="local"
                                imgStyle={{
                                    objectFit: 'contain',
                                }}
                            />
                            <Image
                                style={{
                                    gridArea: 'i',
                                    justifySelf: 'center',
                                    width: '100%',
                                    maxHeight: 150,
                                    minHeight: 150,
                                    // flex: 0
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        min-height: 90px !important;
                                    }
                                `}
                                fluid={data.panier.childImageSharp.fluid}
                                alt="panier"
                                imgStyle={{
                                    objectFit: 'contain',
                                }}
                            />
                        </GridContainer>
                        <FlexDiv
                            style={{
                                justifyContent: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    fontWeight: 600,
                                    fontSize: '1.5rem',
                                    alignSelf: 'center',
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 1.15rem !important;
                                    }
                                `}
                            >
                                ...alors nous avons créé CABADICI !
                            </Text>
                        </FlexDiv>
                    </Section>
                    <Image fluid={data.aliments2.childImageSharp.fluid} />
                    <Text
                        style={{padding: '20px 0', fontSize: '1.5rem', fontWeight: 600, textAlign: 'center'}}
                        css={`font-family: ${props => props.theme.font.title}`}
                    >
                        Vous aussi, cuisinez bon et local
                        <br />
                        sans avoir à y penser !
                    </Text>
                    <FlexDiv style={{justifyContent: 'center'}}>
                        <Image
                            fixed={data.logoPrincipal.childImageSharp.fixed}
                            alt="logo"
                        />
                    </FlexDiv>
                    <FlexDiv
                        style={{
                            justifyContent: 'center',
                            paddingTop: 10,
                        }}
                    >
                        <CabadiciButton
                            color="jauneFort"
                            onClick={() => openConnectForm()}
                            style={{width: 170}}
                        >
                            commander
                        </CabadiciButton>
                    </FlexDiv>
                </>
            )
        }
    </Layout>
));

export default IndexPage;

export const pageQuery = graphql`
    query {
        aliments1: file(relativePath: { eq: "ici-ou/hashtag.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 400, maxWidth: 1000, quality: 90, fit: COVER, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
        }
        aliments2: file(relativePath: { eq: "ici-ou/footer.jpg" }) {
            ...fullWidthImage
        }
        champ: file(relativePath: { eq: "ici-ou/champs.png" }) {
            ...fullWidthImage
        }
        livre: file(relativePath: { eq: "ici-ou/RECETTE.png" }) {
            ...squareImage
        }
        chariot: file(relativePath: { eq: "ici-ou/LISTE.png" }) {
            ...squareImage
        }
        drive: file(relativePath: { eq: "ici-ou/CADDIE.png" }) {
            ...squareImage
        }
        local: file(relativePath: { eq: "ici-ou/BIO.png" }) {
            ...squareImage
        }
        panier: file(relativePath: { eq: "ici-ou/LOCAL.png" }) {
            ...squareImage
        }
        logoPrincipal: file(relativePath: { eq: "logos/logo-rouge.png" }) {
            childImageSharp {
                fixed(height: 150) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        backgroundParenthesesNoires: file(relativePath: { eq: "backgrounds/parentheses-noires-carre.png" }) {
            childImageSharp {
                fluid(maxHeight: 200, quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
    }
`

/*
file(relativePath: { eq: "ici-ou/aliments.png" }) {
    childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2500, quality: 100) {
            ...GatsbyImageSharpFluid
        }
    }
}
livre: file(relativePath: { eq: "ici-ou/1-livre.png" }) {
    childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 100) {
            ...GatsbyImageSharpFixed
        }
    }
}
*/
